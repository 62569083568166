import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';
import createPersistedState from "vuex-persistedstate"
import { SET_TOKEN, GET_TOKEN, REMOVE_TOKEN } from "@/utils/token";
import { currentUser } from "@/api/user";
import * as ragApi from "@/api/rag"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: GET_TOKEN(), //用户唯一标识token
    list: [],
    viewKey: 'all',
    currentUser:null,
    embeddingApis:null
  },
  getters: {
    // done (state) {
    //   return state.list.filter(x => x.result !='').length
    // },
    infolist (state) {
      if (state.viewKey === 'all') {
        return state.list
      }
      if (state.viewKey === 'undone') {
        return state.list.filter(x => !x.done)
      }
      if (state.viewKey === 'done') {
        return state.list.filter(x => x.done)
      }
      return state.list
    },
    currentUser(state){
      return state.currentUser;
    },
    embeddingApis(state){
      return state.embeddingApis;
    }
  },
  mutations: {
    saveToken(state, token) {
      state.token = token;
    },
    initList (state, list) {
      state.list = list
    },
    checkIdExists (state, param) {
      if(param.idResult.length > 0){
        const i = state.list.findIndex(x => x.id === param.id)
        if (i !== -1) {
          state.list[i].result = param.idResult.includes(param.id)
        }
      }
      localStorage.setItem("testDataCode", JSON.stringify(state.list));

    },
    cleanDone (state) {
      state.list = state.list.filter(x => x.done === false)
    },
    changeKey (state, key) {
      state.viewKey = key
    },
    setCurrentUser(state,userInfo){
     state.currentUser = userInfo;
    }
  },
  actions: {
    getList (context) {
      const localData = JSON.parse(localStorage.getItem("testDataCode"));
      if(localData){
        context.commit('initList', localData)
      } else{
        axios.get('/list.json').then(({ data }) => {
          context.commit('initList', data);
        })
      }
    },
    async fetchCurrentUser({ commit }) {
      try {
        const res = await currentUser();
        commit('setCurrentUser', res.data);
        return res.data;
      } catch (error) {
        console.error('Error fetching current user:', error);
        throw error;
      }
    },
    getCurrentUser({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        if (state.currentUser) {
          resolve(state.currentUser);
        } else {
          dispatch('fetchCurrentUser')
            .then(currentUser => {
              resolve(currentUser);
            })
            .catch(error => {
              reject(error);
            });
        }
      });
    },
    async getEmbeddingApis({state }){
      if(!state.embeddingApis){
        const apis = await ragApi.getEmbeddingApis();
        state.embeddingApis = apis.data;
      }
      return state.embeddingApis;
    }
  },
  modules: {},
  //plugins:[createPersistedState()]
});
