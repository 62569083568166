import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './styles/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'highlight.js/styles/default.css'; // Choose a style that you prefer

// 过滤器
Vue.filter('formatDate', function(value) {
  if (!value) return '';
  const date = new Date(value * 1000);
  //获取年份
  const y = date.getFullYear();
  //获取月份，月份是从0开始，需要+1，先＋1转换成字符串，调用字符串的padStart方法，保证位数为2，不足补0
  const m = (date.getMonth() + 1 + '').padStart(2, '0');
  const d = (date.getDate() + '').padStart(2, '0');
 
  const hh = (date.getHours() + '').padStart(2, '0');
  const mm = (date.getMinutes() + '').padStart(2, '0');
  const ss = (date.getSeconds() + '').padStart(2, '0');
 
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;

});


Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
