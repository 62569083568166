//进行axios二次封装:使用请求与响应拦截器
import axios from "axios";
import { GET_TOKEN,GET_TOKEN_A } from "@/utils/token";
import router from "@/router";
import { Message } from "element-ui";

//第一步:利用axios对象的create方法,去创建axios实例(其他的配置:基础路径、超时的时间)
const request = axios.create({
  //基础路径
  baseURL:  process.env.VUE_APP_API_URL,
  // baseURL: "", 
  timeout: 50000, //超时的时间的设置
});
//第二步:request实例添加请求与响应拦截器
request.interceptors.request.use((config) => {
  if (GET_TOKEN()) {
    config.headers.authorization = GET_TOKEN();
  }

  if (GET_TOKEN_A()) {
    config.headers.tokenxz = GET_TOKEN_A();
  }
 
  
  if (!config.headers.authorization && !config.url.includes("login")) {
    // 说明没有token，直接踢回去让登录
    Message({
      message: "token过期请重新登录",
      type: "warning",
    });
    router.replace("/login");
    return;
  }
  //config配置对象,headers属性请求头,经常给服务器端携带公共参数
  //返回配置对象
  return config;
});

//第三步:响应拦截器
request.interceptors.response.use(
  (response) => {
    //成功回调
    //简化数据
    return response.data;
  },
  (error) => {
    // console.log(error)
    //失败回调:处理http网络错误的
    //定义一个变量:存储网络错误信息
    let message = "";
    //http状态码
    const status = error.request.status;
    switch (status) {
      case 401:
        router.replace("/login");
        message = "TOKEN过期";
        break;
      case 403:
        router.replace("/login");
        message = "无权访问";
        break;
      case 404:
        message = "请求地址错误";
        break;
      case 408:
        message = "请求超时，请检查网络质量或稍后重试";
        break;
      case 500:
        message = "服务器出现问题";
        break;
      default:
        message = "网络出现问题,请稍候重试";
        break;
    }
    Message({
      message,
      type: "warning",
    });
    return Promise.reject(error);
  }
);
//对外暴露
export default request;
