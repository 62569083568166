import Vue from "vue";
import VueRouter from "vue-router";
import { GET_TOKEN } from "@/utils/token";
import { Message } from "element-ui";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    redirect: {
      path: "/login",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/home"),
    children: [
      {
        path: "assistant",
        name: "assistant",
        component: () =>
          import(/* webpackChunkName: "assistant" */ "../views/assistant"),
      },
      {
        path: "assistant2",
        name: "assistant2",
        component: () =>
          import(/* webpackChunkName: "assistant2" */ "../views/assistant2"),
      },
      {
        path: "assistant3",
        name: "assistant3",
        component: () =>
          import(/* webpackChunkName: "assistant2" */ "../views/assistant3"),
      },
      {
        path: "gemini",
        name: "gemini",
        component: () =>
          import(/* webpackChunkName: "gemini" */ "../views/gemini"),
      },
      {
        path: "functionCallAssistant",
        name: "functionCallAssistant",
        component: () =>
          import(
            /* webpackChunkName: "functionCallAssistant" */ "../views/functionCallAssistant"
          ),
      },

      {
        path: "realTimeVoice",
        name: "realTimeVoice",
        component: () =>
          import(
            /* webpackChunkName: "realTimeVoice" */ "../views/realTimeVoice"
          ),
      },
      {
        path: "permanentRealTimeVoice",
        name: "permanentRealTimeVoice",
        component: () =>
          import(
            /* webpackChunkName: "permanentRealTimeVoice" */ "../views/permanentRealTimeVoice"
          ),
      },
      {
        path: "rag",
        name: "rag",
        component: () => import("../views/rag"),
      },
      {
        path: "doc-input",
        name: "doc-input",
        component: () => import("../views/rag/DocInput.vue"),
      },
      {
        path: "voiceAssistant",
        name: "voiceAssistant",
        component: () =>
          import(
            /* webpackChunkName: "voiceAssistant" */ "../views/voiceAssistant"
          ),
      },
      {
        path: "dId_talks",
        name: "dId_talks",
        component: () =>
          import(/* webpackChunkName: "dIdTalks" */ "../views/dIdTalks"),
      },
      {
        path: "dId_clips",
        name: "dId_clips",
        component: () =>
          import(/* webpackChunkName: "dIdClips" */ "../views/dIdClips"),
      },
      {
        path: "sys-pm",
        name: "system Prompt",
        component: () =>
          import(/* webpackChunkName: "dId" */ "../views/systemPrompt"),
      },
      {
        path: "dIdCommon",
        name: "dIdCommon",
        component: () =>
          import(/* webpackChunkName: "dIdCommon" */ "../views/dIdCommon"),
      },
      {
        path: "mult-sys-pm",
        name: "multSystemPrompt",
        component: () =>
          import(
            /* webpackChunkName: "multSystemPrompt" */ "../views/multSystemPrompt"
          ),
      },
      {
        path: "blank",
        name: "blank",
        component: () =>
          import(/* webpackChunkName: "multSystemPrompt" */ "../views/blank"),
      },
      {
        path: "corporate-briefings",
        name: "corporate-briefings",
        component: () => import("../views/corporateBriefings"),
      },
      {
        path: "huiyuan",
        name: "huiyuan",
        component: () => import("../views/huiyuan"),
      },
      {
        path: "lancang",
        name: "lancang",
        component: () => import("../views/lancang"),
      },
      {
        path: "testApi",
        name: "testApi",
        component: () => import("../views/testApi"),
      },
      {
        path: "ai",
        name: "ai",
        component: () => import("../views/xingzuo"),
      },
      {
        path: "meetingRoom",
        name: "meetingRoom",
        component: () => import("../views/meetingRoom"),
      },
      {
        path: "new-voiceAssistant",
        name: "new-voiceAssistant",
        component: () =>
          import(
            /* webpackChunkName: "voiceAssistant" */ "../views/newVoiceAssistant"
          ),
      },
      {
        path: "keywords",
        name: "keywords",
        component: () => import("../views/keywords"),
      },
      {
        path: "meeting",
        name: "meeting",
        component: () => import("../views/meeting"),
      },
      {
        path:'conversation',
        name: "conversation",
        component:()=>import("../views/conversation"),
      },
      {
        path:"batchChat",
        name:"batchChat",
        component:()=>import("../views/batchChat"),
      }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const widthList = ["/login", "/"];
router.beforeEach((to, from, next) => {
  // 1.如果访问的是登录页面（无需权限），直接放行
  if (widthList.some((item) => item === to.path)) return next();
  // 2.如果访问的是有登录权限的页面，先要获取token
  const tokenStr = GET_TOKEN();
  console.log("tokenStr", tokenStr);
  // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
  if (!tokenStr) {
    Message({
      message: "请登录",
      type: "warning",
    });
    return next("/login");
  } else {
    next();
  }
});
export default router;
