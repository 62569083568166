/* eslint-disable prettier/prettier */
import request from '@/utils/request';
//登录接口
export function reqLogin(data) {
  return request({
    url: '/api/users/login',
    method: 'POST',
    data,
  });
}


export function currentUser(){
  return request({
    url:'/api/users/currentUser',
    method:"GET"
  })
}