import request from "@/utils/request";
import { GET_TOKEN } from "@/utils/token";
import { fetchEventSource } from "@microsoft/fetch-event-source";
/**
 * 获取content组
 * @param {string} group 
 * @returns 
 */
export function getlistContent(group){
    return request(
        {
            url:"/api/rag/listContent",
            method:"get",
            params:{group}
        }
    )
}

/**
 * 获取content
 * @param {number} id 
 * @returns 
 */
export function getContentById(id){
    return request(
        {
            url:`/api/rag/getContentById/${id}`,
            method:"get"
        }
    )
}
// 获取问题组别
export function getQuestionGroups(){
    return request(
        {
            url:"/api/test-api/getQuestionGroups",
            method:"get",
        }
    )
}
//获取测试结果
export function getTestResult(){
    return request(
        {
            url:"/api/test-api/getTestResult",
            method:"get",
        }
    )
}
/**
 * 插入测试结果
 * @param {string} group 
 * @param {Date} date 
 * @param {string} api 
 * @param {number} score 
 * @returns 
 */
export function insertTestResult(group,date,api,score){
    return request(
        {
            url:"/api/test-api/insertTestResult",
            method:"POST",
            data:{
                group,
                date:new Date(),
                api,
                score
            }
        }
    )
}
/**
 * 根据结果ids获取内容
 * @param {array} ids 
 * @returns 
 */
export function getContentByIds(ids){
    return request(
        {
            url:"/api/test-api/getContentByIds",
            method:"post",
            data:{ids}
        }
    )
}
/**
 * 获取问题组
 * @param {string} group,
 * @param {string} questionGroup 
 * @returns 
 */
export function getQuestion(group,questionGroup){
    return request(
        {
            url:"/api/test-api/getQuestion",
            method:"get",
            params:{group,questionGroup}
        }
    )
}
/**
 * 增加问题组
 * @param {Array} array
 * @returns 
 */
export function addQuestions(array) {
    return request({
        url: "/api/test-api/addQuestions",
        method: "post",
        data: {array}
    })
}
/**
 * 新增问题
 * @param {string} name
 * @param {string} group
 * @param {number} asnid
 * @param {string} question_group
 * @returns 
 */
export function addQuestion(name,group,asnid,question_group){
    return request(
        {
            url:"/api/test-api/addQuestion",
            method:"post",
            data:{name,group,asnid,question_group}
        }
    )
}
//根据问题id获取问题
/**
 * 
 * @param {number} id 
 * @returns 
 */
export function getQuestionById(id){
    return request(
        {
            url:`/api/test-api/getQuestionById/${id}`,
            method:"get",
        }
    )
}
//根据问题id更新问题
/**
 * 
 * @param {number} id 
 * @param {string} name
 * @param {boolean} result
 * @param {array} id_result
 * @returns 
 */
export function updateQuestion(id,name,result,id_result){
    return request(
        {
            url:`/api/test-api/updateQuestion/${id}`,
            method:"put",
            data:{ name,result,id_result}
        }
    )
}

//根据内容id获取问题
/**
 * 
 * @param {number} id 
 * @returns 
 */
export function getQuestionByContentId(id){
    return request(
        {
            url:`/api/test-api/getQuestionByContentId/${id}`,
            method:"get",
        }
    )
}

//根据问题id删除问题
/**
 * 
 * @param {number} id 
 * @returns 
 */
export function deleteQuestion(id){
    return request(
        {
            url:`/api/test-api/deleteQuestion/${id}`,
            method:"delete"
        }
    )
}
export function getPrompt(){
    return request(
        {
            url:"/api/rag/getPrompt",
            method:"get",
        }
    )
}

export function chart(message,group = "def",api = "openai"){
    return request(
        {
            url:"/api/rag/chart/"+api,
            method:"post",
            data:{
                message,group
            }
        }
    )
    
}
/**
 * 
 * @param {string} message 
 * @param {string} group 
 * @returns 
 */
export function chart2(message,chains,group = "def",api = "openai",chatApi='openai',embeddingContentType = 1){
    return request(
        {
            url:"/api/rag/chart2/"+api,
            method:"post",
            data:{
                message,group,chains,chatApi,embeddingContentType
            }
        }
    )
    
}

export function listGroups(api = "openai"){
    return request(
        {
            url:`/api/rag/listGroups/${api}?t=`+new Date().getTime(),
            method:"get",
            
        }
    )
}
/**
 * 
 * @param {string} content
 * @param {number} limit
 * @param {string} group 
 * @param {string} api 
 * @returns 
 */
export function search(content,limit=10,group=1,api = "openai",embeddingContentType=1){
    return request(
        {
            url:`/api/rag/search/${api}`,
            method:"get",
            params:{
                content,group,limit,embeddingContentType
            }
            
        }
    )
}
/**
 * 
 * @param {string} group 
 * @returns 
 */
export function listGroupFiles(group,api = "openai"){
    return request(
        {
            url:"/api/rag/listGroupFiles/"+api,
            method:"get",
            params:{
                group,
                t:new Date().getTime()
            }
        }
    )
}


export function createFromDocument(file,group,api){
    const data = new FormData();
    data.append("files",file);
    data.append("group",group);
    return request(
        {
            url:"/api/rag/createFromDocument/"+api,
            method:"post",
            data
        }
    )
}

export function setPrompt(prompt){
    return request(
        {
            url:"/api/rag/setPrompt",
            method:"post",
            data:{
                prompt
            }
        }
    )
}

export function removeGroupFile(group,file,api = "openai"){
    return request(
        {
            url:"/api/rag/removeGroupFile/"+api,
            method:"get",
            params:{
                group,filename:file
            }
        }
    )
}

export function setChain(chain){
    return request({
        url:'/api/rag/setChain',
        method:'post',
        data:chain
    });
}

export function getChain(name){
    return request({
        url:'/api/rag/getChain',
        method:'get',
        params:name
    });
}

export function listChains(){
    return request({
        url:'/api/rag/listChains',
        method:'get',
    });
}

export function deleteChain(name){
    return request({
        url:'/api/rag/deleteChain',
        method:'get',
        params:{name}
    })
}

export function listQuestions(){
    return request({
        url:'/api/rag/questions/list',
        method:'GET'
    })
}

export function createDocFormString(doc,filename,group,api){
    return request({
        url:'/api/rag/doc/new/'+api,
        method:'POST',
        data:{
            doc,filename,group
        }
    })
}

export function listIncompleteInfo(){
    return request({
        url:'/api/rag/doc/building/info',
        method:'GET',
    })
}

function convertToWebSocketUrl(httpUrl) {
    // 检查是否是以http:// 或 https:// 开头的地址
    if (httpUrl.startsWith("http://")) {
      // 将http替换为ws
      return "ws://" + httpUrl.slice(7);
    } else if (httpUrl.startsWith("https://")) {
      // 将https替换为wss
      return "wss://" + httpUrl.slice(8);
    } else {
      // 如果不是以http:// 或 https:// 开头的地址，抛出错误或采取适当的处理方式
      throw new Error("Invalid HTTP URL");
    }
  }

const url = process.env.VUE_APP_API_URL



export function embedding(api,cb){
    const ws = new WebSocket(convertToWebSocketUrl(url+'/api/rag/embedding')+`?api=${api}&authorization=`+GET_TOKEN());
    ws.onopen=()=>{
        cb('连接建立\n',ws.close);
        cb('请不要离开,离开便停止处理!\n');
    }
    ws.onmessage=(message)=>{
        cb(message.data+'\n',ws.close);
    }
    ws.onclose=(eve)=>{
        cb('连接断开\n',ws.close);
        console.log(eve);
    }
    ws.onerror=(err)=>{
        console.log(err);
    }
}


export function esSync(cb){
    const ws = new WebSocket(convertToWebSocketUrl(url+'/api/rag/sync')+'?api=es&authorization='+GET_TOKEN());
    ws.onopen=()=>{
        cb('连接建立\n',ws.close);
        cb('请不要离开,离开便停止同步!\n');
    }
    ws.onmessage=(message)=>{
        cb(message.data+'\n',ws.close);
    }
    ws.onclose=(eve)=>{
        cb('连接断开\n',ws.close);
        console.log(eve);
    }
    ws.onerror=(err)=>{
        console.log(err);
    }
}

export function rebuildIndex(api){
    return request({
        url:'/api/rag/embeding/rebulidindex/'+api,
        method:'GET',
    })
}

export function getValueById(id,api){
    return request({
        url:`/api/rag/doc/value/${api}/${id}`,
        method:'GET'
    })
}

export function testAPI(cb,ids,searchMethod,embeddingApi,needRerank,searchNum,reankerNum,embeddingContentType){
    const ws = new WebSocket(convertToWebSocketUrl(url+'/api/test-api/testSearch')+'?authorization='+GET_TOKEN());
    ws.onopen=()=>{
        cb({message:'连接建立\n',count:0});
        cb({message:'请稍等!\n'});
        ws.send(JSON.stringify({
            ids,searchMethod,embeddingApi,needRerank,searchNum,reankerNum,embeddingContentType
        }))
    }
    ws.onmessage=(message)=>{
        const  data = JSON.parse(message.data);
        if(data.finlish){
            cb({message:'检测完毕\n'})
        }else{
            cb({message:'连接建立\n',count:data.count})
        }
    }
    ws.onclose=(eve)=>{
        cb('连接断开\n');
        console.log(eve);
    }
    ws.onerror=(err)=>{
        console.log(err);
    }
}

export function getEmbeddingApis(){
    return request({
        url:`/api/rag/listEmbeddingApis`,
        method:'GET'
    })
}

/**
 * 
 * @param {{gid:number,onProgress:(count:number,nums:number)=>void,onError:(err:Error)=>void,onFinlish:()=>void}} gid 
 */
export function optimizeContents({gid,onProgress,onError,onFinlish}){
    fetchEventSource('/api/rag/optimizeContents?gid='+gid,{
        method:'get',
        headers:{
            authorization:GET_TOKEN()
        },
        onopen:()=>{},
        onmessage:(e)=>{
            if(e.event == 'progress'){
                const re = JSON.parse(e.data)
                const nums = re.nums
                const count = re.count
                onProgress(count,nums)
            }else if(e.event == 'error'){
                onError(new Error(e.data))
            }else if(e.event == 'end'){
                console.log('end');
            }
        },
        onerror:(err)=>{
            onError(err)
        },
        onclose:()=>{
            onFinlish()
        }
    })
}